import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SavingsOverview from "../../../components/savings-overview/savings-overview";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import HeroChevron from "../../../components/hero/hero-chevron";
import Icon from "../../../components/custom-widgets/icon";
import MiniBandSection from "../../../components/savings-account/mini-band-section";

//Helpers
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const TitleSection = () => {
  return (
    <section className="container">
      <h1>Savings and Money Market Accounts</h1>
      <h2 className="text-green-60 mb-0">
        You need competitive savings options. Choose the account that fits your goals.
      </h2>
    </section>
  );
};

const SavingsAccount = () => {
  const data = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
        allStrapiProducts(filter: {Name: {regex: "/(\\d+-Month CD Special|\\d+-\\d+-Month CD Special)/"}}) {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
    }
  `);

  const getHighestAPYEligibleCheckingProductData = () => {
    const APY_ELIGIBLE_CHECKING = data.allStrapiProducts.nodes.filter((node) =>
      node.ProductData.some((product) => product.name === "APY - Eligible Checking")
    );

    let maxValue = 0;
    let maxValueProductData = null;

    APY_ELIGIBLE_CHECKING.forEach((product) => {
      product.ProductData.forEach((data) => {
        if (data.name === "APY - Eligible Checking") {
          const percentProValue = parseFloat(data.value.replace("%", ""));
          if (percentProValue > maxValue) {
            maxValue = percentProValue;
            maxValueProductData = product;
          }
        }
      });
    });

    return { value: maxValue, product: maxValueProductData };
  };

  const highestAPYData = getHighestAPYEligibleCheckingProductData();

  const MAX_CD_SPECIAL_APY = highestAPYData.value.toFixed(2) + "%";
  const MAX_CD_PRODUCT = highestAPYData.product ? highestAPYData.product.Name.replace("-Month CD Special", "") : null;

  const heroChevronData = {
    id: "savings-account-hero",
    ...getHeroImgVariables(data),
    altText: "Family playing while cooking in the kitchen.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Earn Interest and Peace of Mind"
          }
        },
        {
          id: 2,
          button: {
            id: "savings-account-hero-instant-open-button",
            type: "anchor-link",
            text: "Open an Account in Minutes",
            url: "#savings-overview-section",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Savings and Money Markets"
    }
  ];

  const title = "Savings Accounts and Money Markets",
    description =
      "Learn about the different types of savings accounts at WaFd Bank, including money market accounts to help you grow faster. Visit us.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/savings-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-savings-money-market-12272022.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <TitleSection />
      <MiniBandSection className="py-0" />
      <SavingsOverview />
      <section className="bg-light" id="cd-calculator-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/thumbnail-hys-cds-02162023-730.jpg"
                alt="Man using laptop at home"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h3 class="font-weight-semi-bold text-success">
                Lock in up to {MAX_CD_SPECIAL_APY} Annual Percentage Yield with our {MAX_CD_PRODUCT} month Certificate
                of Deposit (CD) special!
              </h3>
              <p>
                CDs often pay higher interest rates than savings and money market accounts in exchange for leaving the
                funds on deposit for a fixed period of time.
              </p>
              <AnchorLink
                gatsbyLinkProps={{
                  id: "cd-calculator-link"
                }}
                className="text-decoration-none font-weight-bold"
                to="/personal-banking/savings-account/certificate-of-deposit#cd-rates-calculator"
              >
                See how much interest you can earn with our WaFd CD Rate{" "}
                <span className="text-nowrap">
                  Calculator
                  <Icon lib="fas" name="arrow-right" class="ml-1" />
                </span>
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>
      <ContactInfo bgClass={"bg-white"} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SavingsAccount;
